import { ChapterType, LineType } from "./api_request";

const sortChapters = (
  chapters: ChapterType[],
  novelId: string,
  order: "asc" | "desc" = "asc"
) => {
  return chapters.sort((a, b) => {
    const lineA: number = Number(a.id.replace(`${novelId}/`, ""));
    const lineB: number = Number(b.id.replace(`${novelId}/`, ""));
    return order === "asc" ? lineA - lineB : lineB - lineA;
  });
};

const sortLines = (lines: LineType[], chapterId: string) => {
  return lines.sort((a, b) => {
    const lineA: number = Number(a.id.replace(`${chapterId}/L`, ""));
    const lineB: number = Number(b.id.replace(`${chapterId}/L`, ""));
    return lineA - lineB;
  });
};

const isUpdated = (timestamp?: number): boolean => {
  const dayBefore = new Date();
  dayBefore.setDate(dayBefore.getDate() - 1);
  if (timestamp && timestamp > dayBefore.getTime()) {
    return true;
  }
  return false;
};

export const Util = {
  sortChapters,
  sortLines,
  isUpdated,
};
