import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import UpdateIcon from "@mui/icons-material/Update";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import {
  Backdrop,
  Badge,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { APIRequest, NovelType } from "../utils/api_request";
import { Util } from "../utils/util";

const NovelList = () => {
  const navigate = useNavigate();
  const [novelList, setNovelList] = useState<NovelType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    APIRequest.getNovels().then((respNovels) => {
      respNovels.sort((a, b) => {
        return a.title === b.title ? 0 : a.title < b.title ? -1 : 1;
      });
      setNovelList(respNovels);
      setLoading(false);
    });
  }, []);

  const handleLink = (novelId: string) => {
    navigate(`/reader/${novelId}`);
  };

  const getListClass = () => {
    let maxHeight = "calc(100dvh - 68.5px - 16px - 16px)";

    return {
      width: "100%",
      overflow: "auto",
      maxHeight,
      marginTop: "16px",
    };
  };

  return (
    <>
      <Header />
      <Container>
        <List sx={getListClass()}>
          {novelList.map((novel) => (
            <React.Fragment key={novel.id}>
              <ListItem disablePadding>
                {novel.chapterCount > 0 && (
                  <ListItemButton onClick={() => handleLink(novel.id)}>
                    <ListItemIcon>
                      <Badge
                        color={
                          Util.isUpdated(novel.updated_at)
                            ? "secondary"
                            : "primary"
                        }
                        badgeContent={novel.chapterCount}
                        max={999}
                      >
                        {Util.isUpdated(novel.updated_at) ? (
                          <FiberNewIcon />
                        ) : (
                          <AutoStoriesIcon />
                        )}
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary={novel.title} />
                  </ListItemButton>
                )}
                {novel.chapterCount === 0 && (
                  <ListItemButton disabled>
                    <ListItemIcon>
                      <UpdateIcon />
                    </ListItemIcon>
                    <ListItemText primary={novel.title} />
                  </ListItemButton>
                )}
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default NovelList;
