import PauseIcon from "@mui/icons-material/Pause";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import {
  Backdrop,
  CircularProgress,
  Container,
  Fab,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import HTMLReactParser from "html-react-parser";
import { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { APIRequest, ChapterType, LineType } from "../utils/api_request";
import { Util } from "../utils/util";
import "../scss/Reader.css";
const synth = window.speechSynthesis;

enum PlayStatusType {
  Pause = 0,
  Play = 1,
  PlayFast = 2,
}

enum SpeakStatusType {
  Pause = 0,
  Play = 1,
}

const Reader = () => {
  const { novelId, chapterId } = useParams();
  if (!novelId) {
    throw new Error("novelId is required");
  }

  if (chapterId) {
    window.history.replaceState(null, "", `/reader/${novelId}`);
  }

  const getStartChapterIdx = () => {
    if (chapterId && !Number.isNaN(chapterId)) {
      return { startChapterIdx: Number(chapterId) - 1 };
    }
    const lastReadDataLineId = localStorage.getItem(
      `ReadDataLineId/${novelId}`
    );

    if (!lastReadDataLineId) {
      const lastReadData = localStorage.getItem(`ReadData/${novelId}`);
      return {
        startChapterIdx: lastReadData ? Number(lastReadData) : 0,
      };
    }

    return {
      startChapterIdx: Number(lastReadDataLineId.split("/")[1]) - 1,
      scrollLineId: lastReadDataLineId,
    };
  };

  let { startChapterIdx, scrollLineId } = getStartChapterIdx();

  const [loadIdx, setLoadIdx] = useState(startChapterIdx + 1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [playStatus, setPlayStatus] = useState<PlayStatusType>(
    PlayStatusType.Pause
  );
  const [speakStatus, setSpeakStatus] = useState<SpeakStatusType>(
    SpeakStatusType.Pause
  );
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const listRef = useRef<HTMLUListElement>(null);

  type ShowChapterType = ChapterType & {
    lines: LineType[];
  };
  const [showChapterList, setShowChapterList] = useState<ShowChapterType[]>([]);
  const [chapterList, setChapterList] = useState<ChapterType[]>([]);
  const linePosListRef = useRef<
    {
      lineId: string;
      top: number;
    }[]
  >([]);
  const stop = useRef(false);

  useEffect(() => {
    setLoading(true);
    APIRequest.getChapters(novelId)
      .then((respChapters) => {
        const chapters = Util.sortChapters(respChapters, novelId);
        setChapterList(chapters);
        const chapter = chapters[startChapterIdx];
        APIRequest.getLines(chapter.id).then((respLines) => {
          setShowChapterList([
            ...showChapterList,
            {
              ...chapter,
              lines: Util.sortLines(respLines, chapter.id),
            },
          ]);
          setLoading(false);
        });
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        return;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scrollLineId) {
      window.location.href = `#${scrollLineId}`;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollLineId = undefined;
      window.history.replaceState(null, "", `/reader/${novelId}`);
    }
  }, [showChapterList]);

  const loadMore = async () => {
    if (loadIdx < chapterList.length) {
      const chapter = chapterList[loadIdx];
      const lines = await APIRequest.getLines(chapter.id);
      setShowChapterList([
        ...showChapterList,
        {
          ...chapter,
          lines: Util.sortLines(lines, chapter.id),
        },
      ]);
      setLoadIdx(loadIdx + 1);
      localStorage.setItem(`ReadData/${novelId}`, String(loadIdx + 1));
    } else {
      setHasMore(false);
    }
  };

  const loadBack = async () => {
    const first = showChapterList[0];
    const backIdx =
      (chapterList.findIndex((chapter) => chapter.id === first.id) ?? 0) - 1;

    if (backIdx >= 0) {
      const chapter = chapterList[backIdx];
      setLoading(true);
      const lines = await APIRequest.getLines(chapter.id);
      setShowChapterList([
        {
          ...chapter,
          lines: Util.sortLines(lines, chapter.id),
        },
        ...showChapterList,
      ]);
      localStorage.setItem(`ReadData/${novelId}`, String(backIdx));
      setLoading(false);
    }
  };

  const getListClass = () => {
    let maxHeight = "calc(100dvh - 68.5px - 16px - 16px)";

    return {
      width: "100%",
      bgcolor: "background.paper",
      position: "relative",
      overflow: "auto",
      maxHeight,
      "& ul": { padding: 0 },
      scrollBehavior: "smooth",
    };
  };

  const setPlayInterval = (ms: number) => {
    return setInterval(() => {
      const currentTop = listRef.current?.scrollTop ?? 0;
      listRef.current?.scrollTo({
        top: currentTop + 1,
        left: 0,
        behavior: "auto",
      });
    }, ms);
  };

  const onClickPlay = () => {
    const newPlayStatus =
      playStatus === PlayStatusType.PlayFast
        ? PlayStatusType.Pause
        : playStatus + 1;
    setPlayStatus(newPlayStatus);

    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    switch (newPlayStatus) {
      case PlayStatusType.Pause:
        break;
      case PlayStatusType.Play:
        setIntervalId(setPlayInterval(25));
        break;
      case PlayStatusType.PlayFast:
        setIntervalId(setPlayInterval(15));
        break;
    }
  };

  const stripTags = (text: string) =>
    text.replace(/<([^'">]|"[^"]*"|'[^']*')*>/g, "");

  const removeRuby = (text: string) => {
    return text.replace(/<ruby><rb>(.*?)<\/rb>.*?<\/ruby>/gi, "$1");
  };

  // スコープを外れると発声が止まるのでグローバル変数にする
  // https://stackoverflow.com/questions/23483990/speechsynthesis-api-onend-callback-not-working
  let utterThis = null;

  const onClickSpeak = async () => {
    const newSpeakStatus =
      speakStatus === SpeakStatusType.Play
        ? SpeakStatusType.Pause
        : SpeakStatusType.Play;
    setSpeakStatus(newSpeakStatus);
    if (newSpeakStatus === SpeakStatusType.Pause) {
      synth.cancel();
      stop.current = true;
      return;
    }
    if (newSpeakStatus === SpeakStatusType.Play) {
      stop.current = false;
    }

    const listTop = listRef.current?.scrollTop ?? -1;
    let currentPos =
      linePosListRef.current
        .filter((linePos) => linePos.top > listTop)
        .shift() ?? linePosListRef.current[0];
    let currentPosIdx = linePosListRef.current.findIndex(
      (linePos) => linePos === currentPos
    );
    while (currentPosIdx > 0) {
      if (stop.current) {
        break;
      }
      const line = showChapterList
        .map((chapter) => chapter.lines)
        .flat()
        // eslint-disable-next-line no-loop-func
        .find((line) => line.id === currentPos.lineId);
      if (!line) {
        break;
      }
      const text = stripTags(removeRuby(line.line));
      if (text) {
        // eslint-disable-next-line no-loop-func
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              utterThis = new SpeechSynthesisUtterance(text);
              // 速度を1.5倍にする
              utterThis.rate = 1.25;
              // langをja-JPにする
              utterThis.lang = "ja-JP";
              utterThis.addEventListener("end", () => {
                resolve("end");
              });
              utterThis.addEventListener("error", (e) => {
                reject(e);
              });
              synth.speak(utterThis);
            } catch (e) {
              reject(e);
            }
          }, 300);
        }).catch((e) => {
          console.error(e);
        });
      }
      currentPosIdx++;
      currentPos = linePosListRef.current[currentPosIdx];

      listRef.current?.scrollTo({
        top: currentPos.top - 100,
        left: 0,
        behavior: "smooth",
      });
      onScroll();
    }
  };

  const onTouchStart = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [intervalId]);

  const onTouchEnd = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    if (playStatus > 0 && !intervalId) {
      setTimeout(() => {
        const newIntervalId = setInterval(() => {
          const currentTop = listRef.current?.scrollTop ?? 0;
          listRef.current?.scrollTo({
            top: currentTop + 1,
            left: 0,
            behavior: "auto",
          });
        }, 40);
        setIntervalId(newIntervalId);
      }, 500);
    }
  }, [intervalId, playStatus]);

  const onScroll = () => {
    const listTop = listRef.current?.scrollTop;
    if (!listTop) {
      return;
    }
    const pos = linePosListRef.current
      .filter((linePos) => linePos.top < listTop)
      .pop();
    if (pos) {
      localStorage.setItem(`ReadDataLineId/${novelId}`, pos.lineId);
    }
  };

  const addLinePosition = (ins: HTMLLIElement | null, line: LineType) => {
    if (!ins?.offsetTop) {
      return;
    }
    linePosListRef.current.push({
      lineId: line.id,
      top: ins.offsetTop,
    });
  };

  const onPageChange = (side: "left" | "right") => {
    const currentTop = listRef.current?.scrollTop ?? 0;
    const currentHeight = (listRef.current?.clientHeight ?? 0) * 0.8;
    // rightの場合高さ分下スクロールする
    const scrollHeight = side === "left" ? -currentHeight : currentHeight;
    listRef.current?.scrollTo({
      top: currentTop + scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  const items = (
    <List
      sx={getListClass()}
      subheader={<li />}
      id="scroll-target"
      ref={listRef}
      style={{
        scrollBehavior: "auto",
      }}
    >
      {showChapterList.map((chapter) => (
        <li key={chapter.id}>
          <ul>
            <ListSubheader
              style={{
                fontSize: "1.125rem",
              }}
            >
              {HTMLReactParser(chapter.title)}
            </ListSubheader>
            {chapter.lines.map((line) => (
              <ListItem
                key={line.id}
                ref={(ins) => addLinePosition(ins, line)}
                id={line.id}
              >
                <ListItemText
                  primary={HTMLReactParser(line.line)}
                  primaryTypographyProps={{
                    fontSize: "1.25rem",
                  }}
                />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );

  return (
    <>
      <Header
        settingLinks={[
          {
            label: "ChapterList",
            link: `/chapters/${novelId}`,
          },
        ]}
      />
      <Container
        style={{
          padding: "8px 16px",
        }}
      >
        <div onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
          <InfiniteScroll
            dataLength={showChapterList.length}
            next={loadMore}
            hasMore={hasMore}
            loader={<></>}
            scrollableTarget="scroll-target"
            pullDownToRefresh={true}
            refreshFunction={loadBack}
            pullDownToRefreshThreshold={10}
            onScroll={onScroll}
          >
            {items}
          </InfiniteScroll>
        </div>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="left-click-area" onClick={() => onPageChange("left")} />
      <div className="right-click-area" onClick={() => onPageChange("right")} />
      <Fab
        color="primary"
        aria-label="add"
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
        onClick={onClickPlay}
        disabled={speakStatus === SpeakStatusType.Play}
      >
        {playStatus === PlayStatusType.PlayFast && <PauseIcon />}
        {playStatus === PlayStatusType.Play && <FastForwardIcon />}
        {playStatus === PlayStatusType.Pause && <PlayArrowIcon />}
      </Fab>
      <Fab
        color="primary"
        aria-label="add"
        style={{
          position: "fixed",
          right: "20px",
          bottom: "100px",
        }}
        onClick={onClickSpeak}
        disabled={
          playStatus === PlayStatusType.PlayFast ||
          playStatus === PlayStatusType.Play
        }
      >
        {speakStatus === SpeakStatusType.Play && <PauseIcon />}
        {speakStatus === SpeakStatusType.Pause && <RecordVoiceOverIcon />}
      </Fab>
    </>
  );
};

export default Reader;
