import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { APIRequest } from "../utils/api_request";

const NovelPost = () => {
  const navigate = useNavigate();
  // const [novelList, setNovelList] = useState<NovelType[]>([]);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");

  const onClickSave = async () => {
    setLoading(true);
    await APIRequest.postNovel(url);
    setLoading(false);
    navigate("/");
  };

  // useEffect(() => {
  //   setLoading(true);
  //   APIRequest.getNovels().then((respNovels) => {
  //     setNovelList(respNovels);
  //     setLoading(false);
  //   });
  // }, []);

  // const handleLink = (novelId: string) => {
  //   navigate(`/reader/${novelId}`);
  // };

  // const getListClass = () => {
  //   let maxHeight = "calc(100dvh - 56px - 16px)";

  //   return {
  //     width: "100%",
  //     overflow: "auto",
  //     maxHeight,
  //     marginTop: "16px",
  //   };
  // };

  return (
    <>
      <Header />
      <Container style={{ padding: "16px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8} md={8}>
            <TextField
              required
              id="outlined-required"
              label="URL"
              defaultValue=""
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUrl(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} alignItems="center">
            <Button
              variant="contained"
              disabled={
                !url ||
                url.length === 0 ||
                !url.startsWith("https://ncode.syosetu.com/n")
              }
              onClick={onClickSave}
            >
              登録
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default NovelPost;
