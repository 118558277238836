import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import ChapterList from "./pages/ChapterList";
import NovelList from "./pages/NovelList";
import NovelPost from "./pages/NovelPost";
import Ranking from "./pages/Ranking";
import Reader from "./pages/Reader";
import Setting from "./pages/Setting";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<NovelList />} />
      <Route path="/reader/:novelId" element={<Reader />} />
      <Route path="/reader/:novelId/:chapterId" element={<Reader />} />
      <Route path="/chapters/:novelId" element={<ChapterList />} />
      <Route path="/ranking" element={<Ranking />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/novel" element={<NovelPost />} />
    </Routes>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
