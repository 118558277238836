import axios from "axios";
import { Constants } from "./constants";

const instance = axios.create({
  baseURL: Constants.API_ROOT,
});

export type NovelType = {
  id: string;
  title: string;
  updated_at?: number;
  chapterCount: number;
};

export type ChapterType = {
  id: string;
  title: string;
  isProceed: number;
  updated_at?: number;
};

export type LineType = {
  id: string;
  line: string;
};

const getNovels = async () => {
  const res = await instance.get<NovelType[]>(`/novel`);
  return res.data;
};
const getChapters = async (novelId: string) => {
  const res = await instance.get<ChapterType[]>(`/novel/${novelId}`);
  return res.data;
};
const getLines = async (chapterId: string) => {
  const res = await instance.get<LineType[]>(`/novel/${chapterId}`);
  return res.data;
};

const postNovel = async (novelUrl: string) => {
  const res = await instance.post(`/novel`, {
    novelUrl,
  });
  return res.data;
};

export const APIRequest = {
  getNovels,
  getChapters,
  getLines,
  postNovel,
};
