import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {
  Backdrop,
  Badge,
  CircularProgress,
  Container,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import { APIRequest, ChapterType } from "../utils/api_request";
import { Util } from "../utils/util";

const ChapterList = () => {
  const { novelId } = useParams();
  if (!novelId) {
    throw new Error("novelId is required");
  }

  const navigate = useNavigate();
  const [chapterList, setChapterList] = useState<ChapterType[]>([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    setLoading(true);
    APIRequest.getChapters(novelId).then((respChapters) => {
      setChapterList(
        Util.sortChapters(respChapters, novelId).filter(
          (chapter) => chapter.isProceed === 1
        )
      );
      setLoading(false);
    });
  }, [novelId]);

  const handleLink = (chapterId: string) => {
    navigate(`/reader/${chapterId}`);
  };

  const getListClass = () => {
    let maxHeight = "calc(100dvh - 68.5px - 16px - 16px)";

    return {
      width: "100%",
      overflow: "auto",
      maxHeight,
      marginTop: "16px",
    };
  };

  const onClickSort = () => {
    const newSort = sort === "desc" ? "asc" : "desc";

    setChapterList([
      ...Util.sortChapters(chapterList, novelId, newSort).filter(
        (chapter) => chapter.isProceed === 1
      ),
    ]);

    setSort(newSort);
  };

  return (
    <>
      <Header />
      <Container>
        <List sx={getListClass()}>
          {chapterList.map((chapter) => (
            <React.Fragment key={chapter.id}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleLink(chapter.id)}>
                  <ListItemIcon>
                    <Badge
                      color="secondary"
                      variant="dot"
                      invisible={!Util.isUpdated(chapter.updated_at)}
                    >
                      <AutoStoriesIcon />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary={chapter.title} />
                </ListItemButton>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Fab
        color="primary"
        aria-label="add"
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
        onClick={onClickSort}
      >
        {sort === "asc" && <KeyboardDoubleArrowUpIcon />}
        {sort === "desc" && <KeyboardDoubleArrowDownIcon />}
      </Fab>
    </>
  );
};

export default ChapterList;
